// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bestellformular-vielen-dank-js": () => import("./../src/pages/bestellformular-vielen-dank.js" /* webpackChunkName: "component---src-pages-bestellformular-vielen-dank-js" */),
  "component---src-pages-bestellformular-js": () => import("./../src/pages/bestellformular.js" /* webpackChunkName: "component---src-pages-bestellformular-js" */),
  "component---src-pages-bs-impresum-js": () => import("./../src/pages/bs/impresum.js" /* webpackChunkName: "component---src-pages-bs-impresum-js" */),
  "component---src-pages-bs-index-js": () => import("./../src/pages/bs/index.js" /* webpackChunkName: "component---src-pages-bs-index-js" */),
  "component---src-pages-bs-inspiracija-js": () => import("./../src/pages/bs/inspiracija.js" /* webpackChunkName: "component---src-pages-bs-inspiracija-js" */),
  "component---src-pages-bs-kontakt-hvala-js": () => import("./../src/pages/bs/kontakt-hvala.js" /* webpackChunkName: "component---src-pages-bs-kontakt-hvala-js" */),
  "component---src-pages-bs-kontakt-js": () => import("./../src/pages/bs/kontakt.js" /* webpackChunkName: "component---src-pages-bs-kontakt-js" */),
  "component---src-pages-bs-o-nama-js": () => import("./../src/pages/bs/o-nama.js" /* webpackChunkName: "component---src-pages-bs-o-nama-js" */),
  "component---src-pages-bs-partnerstvo-js": () => import("./../src/pages/bs/partnerstvo.js" /* webpackChunkName: "component---src-pages-bs-partnerstvo-js" */),
  "component---src-pages-bs-postani-partner-hvala-js": () => import("./../src/pages/bs/postani-partner-hvala.js" /* webpackChunkName: "component---src-pages-bs-postani-partner-hvala-js" */),
  "component---src-pages-bs-postani-partner-js": () => import("./../src/pages/bs/postani-partner.js" /* webpackChunkName: "component---src-pages-bs-postani-partner-js" */),
  "component---src-pages-bs-proizvod-bundevino-ulje-js": () => import("./../src/pages/bs/proizvod/bundevino-ulje.js" /* webpackChunkName: "component---src-pages-bs-proizvod-bundevino-ulje-js" */),
  "component---src-pages-bs-proizvod-corokotovo-ulje-js": () => import("./../src/pages/bs/proizvod/corokotovo-ulje.js" /* webpackChunkName: "component---src-pages-bs-proizvod-corokotovo-ulje-js" */),
  "component---src-pages-bs-proizvod-konopljino-ulje-js": () => import("./../src/pages/bs/proizvod/konopljino-ulje.js" /* webpackChunkName: "component---src-pages-bs-proizvod-konopljino-ulje-js" */),
  "component---src-pages-bs-proizvod-laneno-ulje-js": () => import("./../src/pages/bs/proizvod/laneno-ulje.js" /* webpackChunkName: "component---src-pages-bs-proizvod-laneno-ulje-js" */),
  "component---src-pages-bs-proizvodi-js": () => import("./../src/pages/bs/proizvodi.js" /* webpackChunkName: "component---src-pages-bs-proizvodi-js" */),
  "component---src-pages-bs-zastita-podataka-js": () => import("./../src/pages/bs/zastita-podataka.js" /* webpackChunkName: "component---src-pages-bs-zastita-podataka-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-es-404-js": () => import("./../src/pages/es/404.js" /* webpackChunkName: "component---src-pages-es-404-js" */),
  "component---src-pages-es-aviso-legal-js": () => import("./../src/pages/es/aviso-legal.js" /* webpackChunkName: "component---src-pages-es-aviso-legal-js" */),
  "component---src-pages-es-colaboracion-js": () => import("./../src/pages/es/colaboración.js" /* webpackChunkName: "component---src-pages-es-colaboracion-js" */),
  "component---src-pages-es-contacto-gracias-js": () => import("./../src/pages/es/contacto-gracias.js" /* webpackChunkName: "component---src-pages-es-contacto-gracias-js" */),
  "component---src-pages-es-contacto-js": () => import("./../src/pages/es/contacto.js" /* webpackChunkName: "component---src-pages-es-contacto-js" */),
  "component---src-pages-es-formulario-para-pedidos-gracias-js": () => import("./../src/pages/es/formulario-para-pedidos-gracias.js" /* webpackChunkName: "component---src-pages-es-formulario-para-pedidos-gracias-js" */),
  "component---src-pages-es-formulario-para-pedidos-js": () => import("./../src/pages/es/formulario-para-pedidos.js" /* webpackChunkName: "component---src-pages-es-formulario-para-pedidos-js" */),
  "component---src-pages-es-hazte-socio-gracias-js": () => import("./../src/pages/es/hazte-socio-gracias.js" /* webpackChunkName: "component---src-pages-es-hazte-socio-gracias-js" */),
  "component---src-pages-es-hazte-socio-js": () => import("./../src/pages/es/hazte-socio.js" /* webpackChunkName: "component---src-pages-es-hazte-socio-js" */),
  "component---src-pages-es-index-js": () => import("./../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-inspiracion-js": () => import("./../src/pages/es/inspiracion.js" /* webpackChunkName: "component---src-pages-es-inspiracion-js" */),
  "component---src-pages-es-intimidad-js": () => import("./../src/pages/es/intimidad.js" /* webpackChunkName: "component---src-pages-es-intimidad-js" */),
  "component---src-pages-es-producto-aceite-de-comino-negro-ecologico-1-js": () => import("./../src/pages/es/producto/aceite-de-comino-negro-ecologico-1.js" /* webpackChunkName: "component---src-pages-es-producto-aceite-de-comino-negro-ecologico-1-js" */),
  "component---src-pages-es-producto-aceite-de-comino-negro-ecologico-js": () => import("./../src/pages/es/producto/aceite-de-comino-negro-ecologico.js" /* webpackChunkName: "component---src-pages-es-producto-aceite-de-comino-negro-ecologico-js" */),
  "component---src-pages-es-producto-aceite-de-comino-negro-en-capsulas-1-js": () => import("./../src/pages/es/producto/aceite-de-comino-negro-en-capsulas-1.js" /* webpackChunkName: "component---src-pages-es-producto-aceite-de-comino-negro-en-capsulas-1-js" */),
  "component---src-pages-es-producto-aceite-de-comino-negro-en-capsulas-js": () => import("./../src/pages/es/producto/aceite-de-comino-negro-en-capsulas.js" /* webpackChunkName: "component---src-pages-es-producto-aceite-de-comino-negro-en-capsulas-js" */),
  "component---src-pages-es-producto-balsamo-para-la-piel-con-comino-negro-argan-jojoba-aguacate-y-manteca-de-karite-js": () => import("./../src/pages/es/producto/balsamo-para-la-piel-con-comino-negro-argan-jojoba-aguacate-y-manteca-de-karite.js" /* webpackChunkName: "component---src-pages-es-producto-balsamo-para-la-piel-con-comino-negro-argan-jojoba-aguacate-y-manteca-de-karite-js" */),
  "component---src-pages-es-producto-caramelos-de-comino-negro-curcuma-menta-y-limon-js": () => import("./../src/pages/es/producto/caramelos-de-comino-negro-curcuma-menta-y-limon.js" /* webpackChunkName: "component---src-pages-es-producto-caramelos-de-comino-negro-curcuma-menta-y-limon-js" */),
  "component---src-pages-es-producto-champu-de-argan-y-comino-negro-js": () => import("./../src/pages/es/producto/champu-de-argan-y-comino-negro.js" /* webpackChunkName: "component---src-pages-es-producto-champu-de-argan-y-comino-negro-js" */),
  "component---src-pages-es-producto-jabon-de-manteca-de-karite-con-aceite-de-comino-js": () => import("./../src/pages/es/producto/jabon-de-manteca-de-karite-con-aceite-de-comino.js" /* webpackChunkName: "component---src-pages-es-producto-jabon-de-manteca-de-karite-con-aceite-de-comino-js" */),
  "component---src-pages-es-productos-js": () => import("./../src/pages/es/productos.js" /* webpackChunkName: "component---src-pages-es-productos-js" */),
  "component---src-pages-es-sobre-nosotros-js": () => import("./../src/pages/es/sobre-nosotros.js" /* webpackChunkName: "component---src-pages-es-sobre-nosotros-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspiration-js": () => import("./../src/pages/inspiration.js" /* webpackChunkName: "component---src-pages-inspiration-js" */),
  "component---src-pages-kontakt-vielen-dank-js": () => import("./../src/pages/kontakt-vielen-dank.js" /* webpackChunkName: "component---src-pages-kontakt-vielen-dank-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-partner-werden-js": () => import("./../src/pages/partner-werden.js" /* webpackChunkName: "component---src-pages-partner-werden-js" */),
  "component---src-pages-partnerschaft-js": () => import("./../src/pages/partnerschaft.js" /* webpackChunkName: "component---src-pages-partnerschaft-js" */),
  "component---src-pages-produkt-bio-schwarzkummelol-1-js": () => import("./../src/pages/produkt/bio-schwarzkummelol-1.js" /* webpackChunkName: "component---src-pages-produkt-bio-schwarzkummelol-1-js" */),
  "component---src-pages-produkt-bio-schwarzkummelol-js": () => import("./../src/pages/produkt/bio-schwarzkummelol.js" /* webpackChunkName: "component---src-pages-produkt-bio-schwarzkummelol-js" */),
  "component---src-pages-produkt-bonbons-mit-schwarzkummel-kurkuma-minze-und-zitrone-js": () => import("./../src/pages/produkt/bonbons-mit-schwarzkummel-kurkuma-minze-und-zitrone.js" /* webpackChunkName: "component---src-pages-produkt-bonbons-mit-schwarzkummel-kurkuma-minze-und-zitrone-js" */),
  "component---src-pages-produkt-hautbalsam-mit-schwarzkummel-argan-jojoba-avocado-sheabutter-js": () => import("./../src/pages/produkt/hautbalsam-mit-schwarzkummel-argan-jojoba-avocado-sheabutter.js" /* webpackChunkName: "component---src-pages-produkt-hautbalsam-mit-schwarzkummel-argan-jojoba-avocado-sheabutter-js" */),
  "component---src-pages-produkt-schampoo-mit-argan-und-schwarzkummel-js": () => import("./../src/pages/produkt/schampoo-mit-argan-und-schwarzkummel.js" /* webpackChunkName: "component---src-pages-produkt-schampoo-mit-argan-und-schwarzkummel-js" */),
  "component---src-pages-produkt-schwarzkummelol-in-kapseln-1-js": () => import("./../src/pages/produkt/schwarzkummelol-in-kapseln-1.js" /* webpackChunkName: "component---src-pages-produkt-schwarzkummelol-in-kapseln-1-js" */),
  "component---src-pages-produkt-schwarzkummelol-in-kapseln-js": () => import("./../src/pages/produkt/schwarzkummelol-in-kapseln.js" /* webpackChunkName: "component---src-pages-produkt-schwarzkummelol-in-kapseln-js" */),
  "component---src-pages-produkt-sheabutterseife-mit-schwarzkummelol-js": () => import("./../src/pages/produkt/sheabutterseife-mit-schwarzkummelol.js" /* webpackChunkName: "component---src-pages-produkt-sheabutterseife-mit-schwarzkummelol-js" */),
  "component---src-pages-produkte-js": () => import("./../src/pages/produkte.js" /* webpackChunkName: "component---src-pages-produkte-js" */),
  "component---src-pages-uber-uns-js": () => import("./../src/pages/uber-uns.js" /* webpackChunkName: "component---src-pages-uber-uns-js" */)
}

